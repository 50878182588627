<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />

    <!-- นำทาง -->
    <v-card elevation="0" class="v-card-bottom-30">
      <v-card-title class="body-1">
        Ticket
        <v-icon>mdi-chevron-right</v-icon>
        รายละเอียด Ticket
      </v-card-title>
    </v-card>

    <!-- ticket info -->
    <v-card>
      <v-card-title>
        <span>{{ticketInfo.typeName}}</span>
        <v-spacer />
        <span>{{formatDatetime(ticketInfo.ticket.created)}}</span>
      </v-card-title>
      <v-card-title class="pt-0">
        <span>หัวข้อ : {{ticketInfo.ticket.ticketName}}</span>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <v-row>
          <v-col cols="4" md="2" class="pt-1 pb-2">
            <span class="fn-13">ออเดอร์ :</span>
          </v-col>
          <v-col cols="8" md="4" class="pt-1 pb-2">
            <span class="fn-13 color-black">{{ FormatOrder(ticketInfo.ticket.orderID) }}</span>
          </v-col>
          <v-col cols="12" md="5" />
        </v-row>
        <v-row>
          <v-col cols="4" md="2" class="pt-1 pb-2">
            <span class="fn-13">รายละเอียด :</span>
          </v-col>
          <v-col cols="8" md="4" class="pt-1 pb-2">
            <span class="fn-13 color-black">{{ ticketInfo.ticket.description }}</span>
          </v-col>
          <v-col cols="12" md="5" />
        </v-row>
        <v-row>
          <v-col cols="4" md="2" class="pt-1 pb-2">
            <span class="fn-13">ร้านค้า :</span>
          </v-col>
          <v-col cols="8" md="4" class="pt-1 pb-2">
            <span class="fn-13 color-black">{{ ticketInfo.shopName }}</span>
          </v-col>
          <v-col cols="12" md="5" />
        </v-row>

        <v-row>
          <v-col cols="4" md="2" class="pt-1 pb-2">
            <span class="fn-13">สถานะ :</span>
          </v-col>
          <v-col cols="8" md="4" class="pt-1 pb-2">
            <v-autocomplete 
              v-model="statusSelect" placeholder="เลือกสถานะ" :items="statusList"
              item-text="statusName" item-value="statusID" outlined required hide-details dense
              ref="updateStatusSelect"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="5" />
        </v-row>
        <v-row>
          <v-col cols="4" md="2" class="pt-1 pb-2">
            <span class="fn-13">มอบหมาย :</span>
          </v-col>
          <v-col cols="8" md="4" class="pt-1 pb-2">
            <v-autocomplete 
              v-model="staffSelect" placeholder="เลือกพนักงาน" :items="staffList"
              item-text="name" item-value="id" outlined required hide-details dense
              ref="updateStaffSelect"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="5" />
        </v-row>
        <v-row>
          <v-col cols="4" md="2" class="pt-1 pb-2">
            <span class="fn-13">ผุ้ที่เกี่ยวข้อง :</span>
          </v-col>
          <v-col cols="8" md="4" class="pt-1 pb-2">
            <span class="fn-13 color-black">{{ ticketInfo.relateText.slice(3) }}</span>
          </v-col>
          <v-col cols="12" md="5" />
        </v-row>
        <v-row>
          <v-col cols="4" md="2" class="pt-1 pb-2">
            <span class="fn-13">สร้างโดย :</span>
          </v-col>
          <v-col cols="8" md="4" class="pt-1 pb-2">
            <span class="fn-13 color-black">{{ staffList.filter(x => x.id == ticketInfo.ticket.createdBy)[0].name }}</span>
          </v-col>
          <v-col cols="12" md="5" />
        </v-row>
        <v-row>
          <v-col cols="4" md="2" class="pt-1 pb-2">
            <span class="fn-13">ไฟล์ :</span>
          </v-col>
          <v-col cols="8" md="4" class="pt-1 pb-2">
            <span v-for="(item, index) in ticketInfo.file" :key="index">
              <a :href="item.fileUrl" target="_blank" class="pr-1">ไฟล์ {{index + 1}}</a>
            </span>
          </v-col>
          <v-col cols="12" md="5" />
        </v-row>
        <v-row class="pt-2">
          <div></div>
          <v-spacer/>
          <div class="pr-3">
            <v-btn dark :elevation="1" color="primary" class="text-right" @click="updateTicket()">
              <v-icon left>mdi-content-save</v-icon>
              บันทึก
            </v-btn>
          </div>
        </v-row>
      </v-card-text>
    </v-card>

    <div class="py-2">
      <v-btn dark :elevation="1" color="primary" class="text-right" @click="dialogReply.dialog = true">
        <v-icon left>fa-plus</v-icon>
        เพิ่มรายละเอียด
      </v-btn>
    </div>

    <v-card v-for="(item, index) in ticketReply" :key="index" class="mb-4">
      <v-card-title>
        <span>{{item.staffName}}</span>
        <v-spacer />
        <span>{{item.created}}</span>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <v-row>
          <v-col cols="4" md="2" class="pt-1 pb-2">
            <span class="fn-13">รายละเอียด :</span>
          </v-col>
          <v-col cols="8" md="4" class="pt-1 pb-2">
            <span class="fn-13 color-black">{{item.description}}</span>
          </v-col>
          <v-col cols="12" md="5" />
        </v-row>
        <v-row>
          <v-col cols="4" md="2" class="pt-1 pb-2">
            <span class="fn-13">ไฟล์ :</span>
          </v-col>
          <v-col cols="8" md="4" class="pt-1 pb-2">
            <a href=""></a>
            <span v-for="(item, index) in item.fileList" :key="index">
              <a :href="item.fileUrl" target="_blank" class="pr-1">ไฟล์ {{index + 1}}</a>
            </span>
          </v-col>
          <v-col cols="12" md="5" />
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialogReply.dialog" scrollable max-width="50%" persistent :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card elevation="1" class="ma-0">
        <v-card-title>
          <v-icon size="22" color="teal darken-3">fa fa-file-signature</v-icon>
           <span class="ml-3">เพิ่มรายละเอียด</span>
          <v-spacer/>
          <v-btn icon @click="closeDialogReply()"><v-icon color="danger">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text class="overflow-y-auto px-5">
          <v-row>
            <v-col cols="12" md="3" class="pt-1 pb-2">
              <span class="fn-14"> รายละเอียด : </span>
            </v-col>
            <v-col cols="12" md="9" class="pt-1 pb-2">
              <v-textarea
                :value="dialogReply.description"
                @change="value => dialogReply.description = Trim_value(value)"
                ref="dialogReply"
                autocomplete="off"
                outlined
                dense hide-details
                rows="3"
                row-height="9999"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3" class="pt-1 pb-2">
              <span class="fn-14"> แนบไฟล์/รูป : </span>
            </v-col>
            <v-col cols="12" md="9" class="pt-1 pb-2">
              <v-btn
                onclick="document.getElementById('fileInput').click()"
                outlined
                color="primary"
              >
                แนบไฟล์
              </v-btn>
              <v-file-input
                id="fileInput"
                v-model="myFile"
                @change="setDataFile()"
                style="display: none"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col v-for="(item,index) in preFile" :key="index" class="col-3">
              <div class="d-flex">
                <v-img v-if="item.type == 'image/jpeg' || item.type == 'image/png'" class="mt-3 title mb-1" :src="item.url"/>
                <v-icon v-else size="120" class="mt-3 mb-1" color="teal darken-3">fa fa-file-alt</v-icon>
                <v-btn icon color="danger" @click="removeFile(item)">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-row  class="item-center">
            <v-col align="right">
              <v-btn :elevation="1" color="primary" @click="createTicketReply()">
                <v-icon size="20" class="mr-1">mdi-content-save</v-icon>
                <span class="fn-14"><b> บันทึก </b></span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { header_token } from "@/website/token";
import Loading from "@/website/components/Loading";
import { AlertSuccess, AlertWarning, AlertError } from "@/website/global_alert";
import { Trim_value, FormatOrder, formatDatetime } from "@/website/global_function";
import { shopService_dotnet, branchService_dotnet, generalService_dotnet } from "../../global";
export default {
  name: 'FrontWarehouseTicketDetail',

  components: {
    Loading,
  },

  data() {
    return {
      // info for ticket
      ticketID: null,
      branchID: JSON.parse(localStorage.getItem("Branch_BranchID")),
      staffID: JSON.parse(localStorage.getItem("Branch_StaffID")),
      header_token: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("authorization_token"),
      },
  
      // ticket detail
      ticketInfo: null,
      ticketReply: [],

      // update ticket
      statusSelect: null,
      staffSelect: null,
      statusList: [],
      staffList: [],

      // add reply
      dialogReply: {
        dialog: false,
        type: 0,
        name: null,
        description: null,
      },
      myFile: null,
      preFile: [],
      insertFile: [],

      // loading
      page_loading: true,
      loading: false,
    };
  },

  mounted() {
    
  },

  async created() {
    this.ticketID = parseInt(atob(this.$route.query.id));
    await this.getStatus()
    await this.getStaff()
    await this.getTicketDetail()
    await this.getTicketReply()
    this.page_loading = false;
    this.loading = false
    
  },

  methods: {
    Trim_value,
    FormatOrder,
    formatDatetime,

    //#region info for ticket
    async getStaff() {
      let response = await axios.post(
        shopService_dotnet + "StaffBranch/get-staffbranch-list",
        {
          branchId: this.branchID,
        },
        { headers: header_token }
      );
      this.staffList = response.data
    },

    async getStatus() {
      let response = await axios.post(
        branchService_dotnet + "Ticket/get-ticket-status",
        {
          branchID: this.branchID,
        },
        { headers: header_token }
      );
      this.statusList = response.data
    },

    setDataFile() {
      if (this.myFile != null) {
        var url = URL.createObjectURL(this.myFile);
        if (this.myFile.type == "image/png" || this.myFile.type == "image/jpeg") {
          this.preFile.push({url: url, name: this.myFile.name, type:this.myFile.type, file: this.myFile})
        } else {
          this.preFile.push({url: url, name: this.myFile.name, type:this.myFile.type, file: this.myFile })
        }
      }
    },

    removeFile(img) {
      this.preFile = this.preFile.filter(function(item) {
        return item !== img
      })
      this.myFile = null
    },

    async uploadFile(file) {
      var form_data = new FormData();
      form_data.append("file", file);
      form_data.append("bucket", "branch/ticket");
      let response = await axios.post(
        generalService_dotnet + "General/upload-file-with-bucket",
        form_data,
        { headers: this.header_token }
      );
      if (response.status == 200) {
        this.insertFile.push(response.data.file_path);
      }
    },
    //#endregion info for ticket

    //#region ticket detail
    async getTicketDetail() {
      let response = await axios.post(
        branchService_dotnet + "Ticket/get-ticket-detail",
        {
          ticketID: this.ticketID
        },
        { headers: header_token }
      );
      this.ticketInfo = response.data
      this.statusSelect = response.data.ticket.statusID
      this.staffSelect = response.data.ticket.assignTo
    },

    async updateTicket() {
      if(this.statusSelect == null) {
        this.$refs.updateStatusSelect.focus()
        this.errorAlert("กรุณาเลือกสถานะ")
        return
      } else if(this.staffSelect == null) {
        this.$refs.updateStaffSelect.focus()
        this.errorAlert("กรุณาเลือกพนักงาน")
        return
      } 
      let response = await axios.post(
        branchService_dotnet + "Ticket/edit-ticket",
        {
          "ticketID": this.ticketID,
          "statusID": this.statusSelect,
          "assignTo": this.staffSelect,
          "updatedBy": this.staffID
        },
        { headers: header_token }
      );
      if (response.status == 200) {
        await this.getTicketDetail()
        this.successAlert()
      }
    },
    //#endregion ticket detail

    //#region ticket reply
    async getTicketReply() {
      let response = await axios.post(
        branchService_dotnet + "Ticket/get-ticket-reply",
        {
          "ticketID": this.ticketID
        },
        { headers: header_token }
      );
      this.ticketReply = response.data
    },

    async createTicketReply() {
      if (this.dialogReply.description == null || this.dialogReply.description == '') {
        this.$refs.dialogReply.focus()
        this.errorAlert("กรุณากรอกรายละเอียด หรือ อัปโหลดไฟล์")
        return
      }
      
      for (let index = 0; index < this.preFile.length; index++) {
        await this.uploadFile(this.preFile[index].file)
      }

      let response = await axios.post(
        branchService_dotnet + "Ticket/create-ticket-reply",
        {
          "ticketID": this.ticketID,
          "staffID": this.staffID,
          "description": this.dialogReply.description,
          "file": this.insertFile
        },
        { headers: header_token }
      );

      if (response.status == 200) {
        await this.getTicketReply()
        this.successAlert()
        this.closeDialogReply()
      }
    },

    closeDialogReply() {
      this.dialogReply = {
        dialog: false,
        type: 0,
        name: null,
        description: null,
      }
      this.myFile = null
      this.preFile = []
      this.insertFile = []
    },
    //#endregion ticket reply

    //#region alert
    async successAlert(){
      Swal.fire({
        toast: true,
        timer: 1500,
        showConfirmButton: false,
        type: 'success',
        title: 'Success'
      })
    },
    async errorAlert(body){
      Swal.fire({
        type: 'error',
        text: body,
      })
    },
    //#endregion alert
  },
};
</script>

<style scoped>

</style>