import { render, staticRenderFns } from "./ticket-detail.vue?vue&type=template&id=0c229249&scoped=true"
import script from "./ticket-detail.vue?vue&type=script&lang=js"
export * from "./ticket-detail.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c229249",
  null
  
)

export default component.exports